import { styledStitches } from "@/app/design-system";
import { CheckOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Drawer, Input, List, message } from "antd";
import { mapValues, isEmpty } from "lodash-es";
import moment from "moment";

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  AQMRestrictions,
  BasicMatch,
  Clinician,
  ClinicPreference,
  Fit,
  Inclusion,
  Subregion,
} from "../../../api/types";
import { median, UtilizationStatus } from "../../../app/_helpers";
import { IfPermitted } from "../../../app/_helpers/permissions";
import { Title } from "../../../app/_layout/display";
import { Row } from "../../../app/_layout/Flex";
import { clinicianOperations } from "../../../state/models/clinicians";
import { matchActions, matchOperations } from "../../../state/models/matches";
import useTitle from "../../hooks/useTitle";
import { useShallowEqualSelector } from "../../_helpers/redux";
import CheckboxList from "../../_shared/CheckboxList";
import { getClinicianCapacities } from "../../_shared/ClinicianStatus";
import ListContainer from "../../_shared/ListContainer";
import BulkMatchReassignment from "./BulkMatchReassignment";
import ExpandableQueueItem from "./ExpandableQueueItem";
import { CurrentUserContext } from "@/app/app.utils";

import { UserHasAnyPermissions } from "@/app/_helpers/permissions";
import {
  getCliniciansManualCapacity,
  getClinicianVolumeData,
} from "@/state/models/slot-tool/operations";
import { useGetRequeueLog } from "./_api/use-get-match-requeue-log/use-get-match-requeue-log";

const StickyHeader = styledStitches("div", {
  position: "sticky",
  top: "0px",
  zIndex: 11,
  backgroundColor: "$neutral2",
  paddingBottom: "5px",
});

const MatchQueue = () => {
  useTitle("Match Queue");
  const dispatch = useDispatch();

  const [
    aqmExclusions,
    clinicianMap,
    clinicMap,
    aqmTuningParams,
    lastUpdatedQueuedMatchesAt,
    loadingMatches,
    loadingSuggestions,
    matchClinicPreferencesMap,
    matchFitsMap,
    matches,
    matchesInCart,
    matchSlotPreferenceMap,
    showAqmSuggestions,
    showHighAcuityAqmSuggestions,
    subregionMap,
  ] = useShallowEqualSelector((state) => [
    state.matches.aqmExclusions,
    state.clinicians.clinicianMap,
    state.clinics.clinicMap,
    state.matches.aqmTuningParams,
    state.matches.lastUpdatedQueuedMatchesAt,
    state.matches.loadingQueuedMatches,
    state.matches.loadingMatchSuggestions,
    state.matches.matchClinicPreferencesMap,
    state.matches.matchFitsMap,
    state.matches.queuedMatches,
    state.matches.matchesInCart,
    state.matches.matchSlotPreferenceMap,
    state.matches.showAqmSuggestions,
    state.matches.showHighAcuityAqmSuggestions,
    state.clinics.subregionMap,
  ]);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [filterClientMatches, setFilterClientMatches] = useState(true);
  const [filterByFitStatus, setFilterByFitStatus] = useState(false);
  const [fitFilter, setFitFilter] = useState<{
    [clinicianId: number]: { couples: boolean; individual: boolean };
  }>({});
  const [drawerFitFilter, setDrawerFitFilter] = useState<{
    [clinicianId: number]: { couples: boolean; individual: boolean };
  }>({});
  const [drawerClinicianFilter, setDrawerClinicianFilter] = useState({});
  const [clinicianFilter, setClinicianFilter] = useState({});
  const [drawerLocationFilter, setDrawerLocationFilter] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [drawerTeletherapyFilter, setDrawerTeletherapyFilter] = useState(true);
  const [teletherapyFilter, setTeletherapyFilter] = useState(true);
  const [drawerServiceTypeFilter, setDrawerServiceTypeFilter] = useState({
    couples: true,
    individual: true,
  });
  const [serviceTypeFilter, setServiceTypeFilter] = useState({
    couples: true,
    individual: true,
  });
  const [searchValue, setSearchValue] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const [showMatchesWithNoFits, setShowMatchesWithNoFits] = useState(true);

  const { data: matchesRequeueLogs } = useGetRequeueLog({
    matchIds: matches.map((m) => m.id),
    enabled: !loadingMatches,
  });

  useEffect(() => {
    dispatch(matchOperations.getQueuedMatches(true, true));
    dispatch(matchOperations.setAqmTuningParams());
    dispatch(clinicianOperations.getManualSlots());
  }, [dispatch]);

  const aqmInclusions: Inclusion[] = useMemo(() => {
    return Object.values(matchesInCart).map((cartItem) => {
      const { fit, match, slot } = cartItem;
      if (slot) {
        return {
          match: match,
          slot: {
            clinician_id: fit.clinician,
            day_of_week: slot.day_of_week,
            fit_id: fit.id,
            fit_score: fit.score,
            match_id: match.id,
            note: slot.note,
            slot_id: `${slot.clinician_id}${slot.day_of_week}${slot.start_time}`,
            recurrence: slot.recurrence,
            start_date: slot.start_date,
            start_time: slot.start_time,
            subregion_id: slot.subregion_id,
          },
        };
      } else {
        return {
          match: match,
          slot: {
            clinician_id: fit.clinician,
            fit_id: fit.id,
            fit_score: fit.score,
            match_id: match.id,
            note: null,
            recurrence: "weekly",
          },
        };
      }
    });
  }, [matchesInCart]);

  // Turning off updating suggestions when matches are added to the cart

  // const chosenSlotFits = useMemo(() => {
  //   return JSON.stringify(
  //     aqmInclusions.map(inclusion => {
  //       if ("slot_id" in inclusion.slot) {
  //         return [inclusion.slot.fit_id, inclusion.slot.slot_id];
  //       }
  //       return [inclusion.slot.fit_id];
  //     }),
  //   );
  // }, [aqmInclusions]);

  const refreshMatchSuggestions = () => {
    if (showAqmSuggestions || showHighAcuityAqmSuggestions) {
      const aqmRestrictions: AQMRestrictions = {
        exclusions: aqmExclusions,
        inclusions: aqmInclusions,
      };
      const highAcuityOnly = !!showHighAcuityAqmSuggestions;
      dispatch(
        matchOperations.getMatchSuggestions(
          aqmRestrictions,
          aqmTuningParams,
          highAcuityOnly,
          forceRefresh,
        ),
      );
      setForceRefresh(false);
    } else {
      dispatch(matchOperations.clearMatchSuggestions());
    }
  };

  // Refresh suggestions when conditions change
  useEffect(refreshMatchSuggestions, [
    aqmExclusions,
    aqmTuningParams,
    // chosenSlotFits,
    dispatch,
    locationFilter,
    showAqmSuggestions,
    showHighAcuityAqmSuggestions,
  ]);

  useEffect(() => {
    if (matches.length) {
      // Set Consult Clinician Filter
      const clinicianFilter = {};
      matches.forEach((m: BasicMatch) => {
        if (m.created_by_clinician) {
          clinicianFilter[m.created_by_clinician] === undefined
            ? (clinicianFilter[m.created_by_clinician] = true)
            : (clinicianFilter[m.created_by_clinician] =
                clinicianFilter[m.created_by_clinician]);
        }
      });
      setClinicianFilter(clinicianFilter);
      setDrawerClinicianFilter(clinicianFilter);
    }
  }, [matches, lastUpdatedQueuedMatchesAt]);

  useEffect(() => {
    // Set Location Filter
    const locationFilter = {};
    Object.values(subregionMap).forEach((sr: Subregion) => {
      locationFilter[sr.id] = locationFilter[sr.id] ?? true;
    });
    setLocationFilter(locationFilter);
    setDrawerLocationFilter(locationFilter);
  }, [subregionMap]);

  useEffect(() => {
    // Set Fit Clinician Filter
    if (Object.keys(clinicianMap).length !== Object.keys(fitFilter).length) {
      setFitFilter(
        mapValues(clinicianMap, () => {
          return { couples: true, individual: true };
        }),
      );
      setDrawerFitFilter(
        mapValues(clinicianMap, () => {
          return { couples: true, individual: true };
        }),
      );
    }
  }, [clinicianMap, fitFilter]);

  const getClinicianCapacity = useSelector(getClinicianCapacities);

  const toggleFitStatusFilter = () => {
    // reset fit filter to include all clinicians
    let newFitFilter;
    if (filterByFitStatus) {
      newFitFilter = mapValues(clinicianMap, () => {
        return serviceTypeFilter;
      });
    } else {
      const filterFunction = (clinicianId: number) => {
        const clinicianStatus = getClinicianCapacity(clinicianId);
        return {
          couples:
            clinicianStatus.couplesStatus === UtilizationStatus.Available,
          individual: clinicianStatus.status === UtilizationStatus.Available,
        };
      };
      newFitFilter = mapValues(clinicianMap, (clinician) =>
        filterFunction(clinician.id),
      );
    }
    setFilterByFitStatus(!filterByFitStatus);
    setFitFilter(newFitFilter);
    setDrawerFitFilter(newFitFilter);
  };

  const closeFilterDrawer = () => {
    setClinicianFilter(drawerClinicianFilter);
    setLocationFilter(drawerLocationFilter);
    setTeletherapyFilter(drawerTeletherapyFilter);
    setFitFilter(drawerFitFilter);
    setServiceTypeFilter(drawerServiceTypeFilter);
    setShowFilterDrawer(false);
    message.success("Applied match queue filters");
  };

  const getVolumeData = (excludeWheel?: boolean) => {
    dispatch(getClinicianVolumeData([], excludeWheel, true));
    dispatch(getCliniciansManualCapacity());
  };

  const cuser = React.useContext(CurrentUserContext);

  useEffect(() => {
    if (UserHasAnyPermissions(cuser, ["IsMatchingAdmin"])) {
      getVolumeData();
    } else {
      if (
        UserHasAnyPermissions(cuser, [
          "IsRequeuer",
          "IsSuperUser",
          "IsConsultClinician",
        ])
      ) {
        getVolumeData(true);
      }
    }
  }, []);

  const renderFilterDrawer = () => {
    const flatFitFilter = {};
    Object.entries(drawerFitFilter).forEach(([key, value]) => {
      flatFitFilter[key] = value.couples || value.individual;
    });
    return (
      <Drawer
        title={
          <Row layout="space-between center">
            <span>Filter Match Queue</span>
            <Button
              onClick={closeFilterDrawer}
              type="primary"
              icon={<CheckOutlined />}
            />
          </Row>
        }
        closable={false}
        onClose={closeFilterDrawer}
        open={showFilterDrawer}
      >
        {Object.keys(drawerClinicianFilter).length > 1 && (
          <>
            <CheckboxList
              title={"Consult Clinician"}
              data={Object.keys(drawerClinicianFilter)
                .map((id: string) => clinicianMap[id])
                .filter(Boolean)}
              renderItemName={(c: Clinician) =>
                c.first_name + " " + c.last_name
              }
              renderKey={(c) => c.id}
              checkedMap={drawerClinicianFilter}
              updateCheckedMap={(checkedMap: { [key: string]: boolean }) =>
                setDrawerClinicianFilter(checkedMap)
              }
            />
            <Divider />
          </>
        )}
        <CheckboxList
          title={"Locations"}
          data={Object.values(subregionMap)}
          renderItemName={(sr: Subregion) => sr.display_name}
          renderKey={(sr) => sr.id}
          checkedMap={drawerLocationFilter}
          updateCheckedMap={(checkedMap: { [key: string]: boolean }) =>
            setDrawerLocationFilter(checkedMap)
          }
        />
        <Divider />
        <CheckboxList
          title={"Service Types"}
          data={["individual", "couples"]}
          renderItemName={(x) => x.charAt(0).toUpperCase() + x.slice(1)}
          renderKey={(x) => x}
          checkedMap={drawerServiceTypeFilter}
          updateCheckedMap={(checkedMap: { [key: string]: boolean }) =>
            setDrawerServiceTypeFilter({
              ...drawerServiceTypeFilter,
              ...checkedMap,
            })
          }
        />
        <Divider />
        <Checkbox
          checked={drawerTeletherapyFilter}
          onChange={(e) => setDrawerTeletherapyFilter(e.target.checked)}
        >
          Teletherapy
        </Checkbox>
        <Divider />
        <Checkbox checked={showMatchesWithNoFits} onChange={e => setShowMatchesWithNoFits(e.target.checked)}>Show matches with no fits</Checkbox>
        <CheckboxList
          title={"Clinician Fits"}
          data={Object.keys(drawerFitFilter).map(
            (id: string) => clinicianMap[id],
          )}
          renderItemName={(c: Clinician) => c.first_name + " " + c.last_name}
          renderKey={(c) => c.id}
          checkedMap={flatFitFilter}
          updateCheckedMap={(checkedMap: { [key: string]: boolean }) => {
            const obj = {};
            Object.entries(checkedMap).forEach(([key, value]) => {
              obj[key] = {
                couples: value && serviceTypeFilter.couples,
                individual: value && serviceTypeFilter.individual,
              };
            });
            setDrawerFitFilter(obj);
          }}
        />
      </Drawer>
    );
  };

  const filterByClinician = (match: BasicMatch) => {
    return (
      !match.created_by_clinician ||
      (match.created_by_clinician &&
        clinicianFilter[match.created_by_clinician])
    );
  };
  const filterByLocation = (match: BasicMatch) => {
    const matchClinicPreferences = matchClinicPreferencesMap[match.id] || [];
    if (!isEmpty(matchClinicPreferences)) {
      // Filter old matches by Clinic Preferences
      return matchClinicPreferences.some((cp: ClinicPreference) => {
        const subregionId = clinicMap[cp.clinic_id].subregion;
        return cp.preference_level === "match" && locationFilter[subregionId];
      });
    } else if (!isEmpty(matchSlotPreferenceMap[match.id])) {
      // Filter new Matches by MSP
      const matchMSP = matchSlotPreferenceMap[match.id];
      return matchMSP.some(
        (msp) =>
          !msp.is_general &&
          (msp.subregion === null
            ? teletherapyFilter
            : locationFilter[msp.subregion]),
      );
    }
    // This match has no prefs, just show it. (outlier)
    return true;
  };

  const filterByFit = (match: BasicMatch) => {
    const matchFits = matchFitsMap[match.id] || [];
    if (matchFits.length === 0 && showMatchesWithNoFits) { return true }
    return matchFits.some((fit: Fit) => {
      const clinicianIsChecked =
        fitFilter[fit.clinician] &&
        fitFilter[fit.clinician][match.service_type];
      return clinicianIsChecked;
    });
  };

  const filterByServiceType = (match: BasicMatch) => {
    return serviceTypeFilter[match.service_type];
  };

  // Apply filters
  const filteredMatches = matches
    .filter(filterByClinician)
    .filter(filterByLocation)
    .filter(filterByFit)
    .filter(filterByServiceType)
    .filter((match) =>
      searchValue
        ? (match.couple?.initials ?? match.client.initials)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        : true,
    );

  const queuedMatches = filteredMatches.filter(
    (match) =>
      match.match_after === null ||
      moment(match.match_after).isBefore(moment()),
  );

  const delayedMatches = filteredMatches.filter(
    (match) =>
      match.match_after !== null && moment(match.match_after).isAfter(moment()),
  );

  const daysMedian = median(
    queuedMatches.map((match: BasicMatch) =>
      moment().diff(
        moment(match.consult ? match.consult.start_time : match.created_at),
        "days",
      ),
    ),
  );

  return (
    <ListContainer>
      {renderFilterDrawer()}
      <StickyHeader>
        <Row layout="space-between center" style={{ marginBottom: "5px" }}>
          <div style={{ flex: 1 }}>
            <Divider orientation="left" style={{ marginBottom: "5px" }}>
              <Title margin="0px">
                Showing {filteredMatches.length} of {filteredMatches.length}{" "}
                Queued Matches
              </Title>
            </Divider>
          </div>
          <div style={{ flex: 1 }}>
            <Divider orientation="right" style={{ marginBottom: "5px" }}>
              <Row layout="center center">
                <Button
                  onClick={() => dispatch(matchOperations.openAqmScorecard())}
                  style={{ marginRight: "10px" }}
                >
                  AQM Scorecard
                </Button>
                <BulkMatchReassignment />
                <Button onClick={() => setShowFilterDrawer(true)}>
                  Filter Match Queue
                </Button>
              </Row>
            </Divider>
          </div>
        </Row>
        <h3>Queue Summary</h3>
        <Row>
          <Row layout={"flex-start center"}>
            <ul>
              <li>
                High acuity matches:{" "}
                {
                  filteredMatches.filter((m) => m.priority === "high_acuity")
                    .length
                }
              </li>
              <li>
                High priority matches:{" "}
                {
                  filteredMatches.filter((m) => m.priority === "high_priority")
                    .length
                }
              </li>
            </ul>
          </Row>
        </Row>
        <Row layout={"space-between center"}>
          <IfPermitted
            permissions={["IsSuperUser", "IsMatchingAdmin"]}
            requireAll={false}
          >
            <Checkbox
              defaultChecked={true}
              onChange={() => setFilterClientMatches(!filterClientMatches)}
            >
              Filter Client Matches
            </Checkbox>
            <Checkbox defaultChecked={false} onChange={toggleFitStatusFilter}>
              Filter By Available Clinicians
            </Checkbox>
            <Checkbox
              checked={showHighAcuityAqmSuggestions}
              onChange={() => {
                setForceRefresh(true);
                const newValue = !showHighAcuityAqmSuggestions;
                dispatch(
                  matchActions.setShowHighAcuityAqmSuggestions(newValue),
                );
                if (newValue === true) {
                  dispatch(matchActions.setShowAqmSuggestions(false));
                }
              }}
            >
              Show High-Acuity Suggestions
            </Checkbox>
            <Checkbox
              checked={showAqmSuggestions}
              onChange={() => {
                setForceRefresh(true);
                const newValue = !showAqmSuggestions;
                dispatch(matchActions.setShowAqmSuggestions(newValue));
                if (newValue === true) {
                  dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
                }
              }}
            >
              Show Suggestions
            </Checkbox>
          </IfPermitted>
          <Input
            style={{ width: "180px" }}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            addonAfter={<SearchOutlined />}
            placeholder="Search client initials..."
          />
        </Row>
      </StickyHeader>
      <Row layout={"start center"}>
        <div style={{ flex: 1 }}>
          <Divider orientation="left" style={{ marginBottom: "5px" }}>
            <Title margin="0px" size="sm">
              Client Initials
            </Title>
          </Divider>
        </div>
        <div style={{ flex: 1 }}>
          <Divider orientation="left" style={{ marginBottom: "5px" }}>
            <Title margin="0px" size="sm">
              Consult Clinician
            </Title>
          </Divider>
        </div>
        <div style={{ flex: 1 }}>
          <Divider orientation="right" style={{ marginBottom: "5px" }}>
            <Title margin="0px" size="sm">
              Queued
            </Title>
          </Divider>
        </div>
      </Row>
      <Row layout="space-between center" style={{ margin: "0 20px 10px 16px" }}>
        <div>
          Last updated:{" "}
          {lastUpdatedQueuedMatchesAt
            ? moment(lastUpdatedQueuedMatchesAt).format("h:mm a")
            : "-"}
        </div>
        <div>Median {daysMedian} days in Queue</div>
      </Row>
      <List
        itemLayout="vertical"
        loading={loadingMatches || loadingSuggestions}
        locale={{ emptyText: "Match queue is empty!" }}
        dataSource={queuedMatches}
        renderItem={(match: BasicMatch) => (
          <ExpandableQueueItem
            match={match}
            queueIdx={match.queue_rank ?? 0}
            fitFilter={filterClientMatches ? fitFilter : undefined}
            matchRequeueLogs={matchesRequeueLogs?.[match.id]}
          />
        )}
      />
      <Row layout="space-between center" style={{ marginBottom: "5px" }}>
        <div style={{ flex: 1 }}>
          <Divider orientation="left" style={{ marginBottom: "5px" }}>
            <Title margin="0px">Delayed Matches</Title>
          </Divider>
        </div>
        <div style={{ flex: 1 }}>
          <Divider orientation="right" style={{ marginBottom: "5px" }}>
            <Title margin="0px">Queued</Title>
          </Divider>
        </div>
      </Row>
      <List
        itemLayout="vertical"
        loading={loadingMatches}
        locale={{ emptyText: "No delayed matches" }}
        dataSource={delayedMatches}
        renderItem={(match: BasicMatch) => (
          <ExpandableQueueItem
            match={match}
            queueIdx={match.queue_rank ?? 0}
            fitFilter={filterClientMatches ? fitFilter : undefined}
          />
        )}
      />
    </ListContainer>
  );
};
export default MatchQueue;
