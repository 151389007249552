import { message, notification } from "antd";
import { AxiosResponse } from "axios";
import { chunk } from "lodash-es";
import moment from "moment";
import React from "react";

import api, { wrapApiCall } from "../../../api";
import {
  APIConflictOfInterest,
  AQMResponse,
  AQMRestrictions,
  AQMTuningParams,
  BasicMatch,
  BasicUser,
  CCVRData,
  ClinicianMatchTag,
  ClinicPreference,
  ExtendedMatch,
  Fit,
  IdMap,
  MatchFit,
  MatchPriority,
  MatchSlotPreference,
  MatchTag,
  NotificationFields,
} from "../../../api/types";
import { alphabetizeBy } from "../../../app/_helpers";
import { getPendingMatches } from "../clinicians/operations";
import { AsyncAction, AsyncActionCreator } from "../types";
import { matchActions, MyConsultsFilter } from "./";
import { MatchingSessionDescription } from '@/app/consults-and-matching/ShoppingCart';

export const getTags: AsyncActionCreator = () => async (dispatch) => {
  try {
    const response = await wrapApiCall(api.get("/ehr/tags/"), dispatch);
    dispatch(matchActions.loadTags(response.data));
  } catch (e) {
    console.error("GetTags Catch");
  }
};

export const bulkMatchReassignment: AsyncActionCreator =
  (fromId: number, toId: number) => async (dispatch, getState) => {
    const matchIds = getState()
      .matches.queuedMatches.filter((match) => match.assignee === fromId)
      .map((match) => match.id);
    dispatch(updateAssignee(matchIds, toId));
  };

export const updateAssignee: AsyncActionCreator =
  (matchIds: number[], userId: number) => async (dispatch) => {
    try {
      const response: AxiosResponse<{
        match_ids: number[];
        assignee_id: number;
      }> = await wrapApiCall(
        api.post("/ehr/matches/v2/update_assignee/", {
          matchIds,
          userId,
        }),
        dispatch,
      );
      dispatch(
        matchActions.updateMatchAssignees({
          matchIds: response.data.match_ids,
          assigneeId: response.data.assignee_id,
        }),
      );
      message.success("Match(es) reassigned!");
    } catch (e) {
      message.error("Error updating assignee, please refresh.");
    }
  };

export const getAssignableUsers: AsyncActionCreator =
  () => async (dispatch) => {
    try {
      const response: AxiosResponse<BasicUser[]> = await wrapApiCall(
        api.get("/ehr/matches/v2/get_assignable_users/"),
        dispatch,
      );
      dispatch(
        matchActions.loadAssignableUsers(
          response.data.sort((a, b) => alphabetizeBy(a, b, "first_name")),
        ),
      );
    } catch (e) {
      console.error("Error getting queue managers.");
    }
  };

export const getInclusiveCategories: AsyncActionCreator =
  () => async (dispatch) => {
    try {
      const response = await wrapApiCall(
        api.get("/ehr/tags/list_inclusive_categories/"),
        dispatch,
      );
      dispatch(matchActions.loadInclusiveCategories(response.data));
    } catch (e) {
      console.error("getInclusiveCategories Catch");
    }
  };

export const getQueuedMatches: AsyncActionCreator =
  (setLoading: boolean = true, getRelatedModels: boolean = false) =>
  async (dispatch, getState) => {
    const isLoading = getState().matches.loadingQueuedMatches;
    if (setLoading && !isLoading) {
      dispatch(matchActions.fetchQueuedMatches());
    } else {
      return;
    }
    try {
      const response: AxiosResponse<BasicMatch[]> = await wrapApiCall(
        api.get("/ehr/matches/v2/queue/"),
        dispatch,
      );
      if (getRelatedModels) {
        dispatch(getRelatedModelsForMatches(response.data.map((m) => m.id)));
      }
      const matches = response.data;
      dispatch(matchActions.saveMyMatches(matches));
      dispatch(matchActions.loadQueuedMatches(matches));
    } catch (e) {
      console.error("FetchQueuedMatches Catch");
      dispatch(matchActions.loadQueuedMatches([]));
    }
  };

export const clientEmailExists: AsyncActionCreator =
  (email: string, callback: (isEmail: boolean) => void) => async (dispatch) => {
    try {
      const response = await wrapApiCall(
        api.get("/ehr/client_exists_by_email/" + email),
        dispatch,
      );
      callback(response.data.id > 0);
    } catch (e) {
      return callback(false);
    }
  };

export const saveMatchFits: AsyncActionCreator =
  (
    matchFits: MatchFit[],
    queue_condition_id: string,
    description: MatchingSessionDescription,
    description_other: string,
    onFinish?: () => unknown,
  ) =>
  async (dispatch, getState) => {
    const paths = getState().matches.queueConditionPaths;
    const resultsId = getState().matches.resultsId;
    dispatch(matchActions.setMatchFlowLoading());
    try {
      await wrapApiCall(
        api.post("/ehr/matchtool/save_match_fits/", {
          matchFits,
          queue_condition_id,
          description,
          description_other,
          paths,
          resultsId,
        }),
        dispatch,
      );
      message.success("Matches successful");
      dispatch(matchActions.setShowAqmSuggestions(false));
      dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
      matchFits.forEach((matchFit) =>
        dispatch(matchActions.removeMatchFromCart(matchFit.matchId)),
      );
      dispatch(matchActions.finishMatchFlowLoading());
      dispatch(getQueuedMatches());
      dispatch(getPendingMatches());
      dispatch(getMyMatches());
    } catch (e: any) {
      notification.error({
        message: "Unable to save matches",
        description: e.response?.data,
        duration: 60,
        placement: "topLeft",
        style: {
          width: "calc(100vw - 48px)",
          maxHeight: "137px",
          overflow: "auto", // allow the user to scroll down if necessary
        },
      });
      dispatch(matchActions.finishMatchFlowLoading());
    } finally {
      if (onFinish) {
        onFinish();
      }
    }
  };

export const requeueMatch =
  (matchId: number, reason: string, removeSelectedFit: boolean, requeueReasonOther: string): AsyncAction =>
  async (dispatch) => {
    try {
      await wrapApiCall(
        api.put(`/ehr/matches/v2/${matchId}/requeue/`, {
          reason,
          removeSelectedFit,
          requeueReasonOther,
        }),
        dispatch,
      );
      message.success("Requeue successful");
      dispatch(matchActions.finishMatchFlowLoading());
      dispatch(getMyMatches());
      dispatch(getQueuedMatches());
      dispatch(getPendingMatches());
    } catch (e) {
      message.error("Unable to requeue.");
      dispatch(matchActions.finishMatchFlowLoading());
    }
  };

interface MyMatchesResponse {
  ccvr_data?: CCVRData;
  matches: BasicMatch[];
}

export const getMyMatches: AsyncActionCreator =
  (page?: number) => async (dispatch, getState) => {
    const clinicianFilter: MyConsultsFilter =
      getState().matches.myConsultsFilter;
    const filterParams = (() => {
      switch (clinicianFilter.subset) {
        case "my_team":
          return `&consult_clinician_ids=${getState().auth.myTeamClinicianIds.join(
            ",",
          )}`;
        case "clinician":
          return `&consult_clinician_ids=${clinicianFilter.clinicianId}`;
        default:
          return ``;
      }
    })();

    dispatch(matchActions.fetchingMyMatches(true));
    try {
      const response: AxiosResponse<MyMatchesResponse> = await wrapApiCall(
        api.get(`/ehr/matches/v2/my_matches/?page=${page || 1}${filterParams}`),
        dispatch,
      );
      const data = response.data;
      dispatch(getMatchNPSForms(data.matches.map((m) => m.id)));
      dispatch(matchActions.saveMyMatches(data.matches));
      if (data.ccvr_data) {
        dispatch(matchActions.setCCVRData(data.ccvr_data));
      }
      dispatch(matchActions.fetchingMyMatches(false));
    } catch (e) {
      dispatch(matchActions.fetchingMyMatches(false));
    }
  };

// supplementary route to get all matches matchingclient initials
export const searchMyMatches: AsyncActionCreator =
  (options: { initials?: string; email?: string }) =>
  async (dispatch, getState) => {
    const { initials, email } = options;
    const clinicianFilter: MyConsultsFilter =
      getState().matches.myConsultsFilter;
    const urlParams = new URLSearchParams();

    // require at least one of initials, or email
    if (![initials, email].some((e) => !!e)) {
      return;
    }

    switch (clinicianFilter.subset) {
      case "my_team":
        urlParams.append(
          "consult_clinician_ids",
          getState().auth.myTeamClinicianIds.join(","),
        );
        break;
      case "clinician":
        if (!clinicianFilter.clinicianId) {
          break;
        }
        urlParams.append(
          "consult_clinician_ids",
          clinicianFilter.clinicianId!.toString(),
        );
        break;
    }

    if (initials) {
      urlParams.append("client_initials", initials);
    }
    if (email) {
      urlParams.append("client_email", email);
    }

    dispatch(matchActions.fetchingMyMatches(true));
    try {
      const response: AxiosResponse<MyMatchesResponse> = await wrapApiCall(
        api.get(`/ehr/matches/v2/my_matches/?${urlParams.toString()}`),
        dispatch,
      );
      const data = response.data;
      dispatch(getMatchNPSForms(data.matches.map((m) => m.id)));
      dispatch(matchActions.saveMyMatches(data.matches));
      dispatch(matchActions.fetchingMyMatches(false));
    } catch (e) {
      dispatch(matchActions.fetchingMyMatches(false));
    }
  };

export const getMatchNPSForms: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    const response = await wrapApiCall(
      api.post("/ehr/matchtool/consult_feedback_by_match_id/", {
        match_ids: matchIds,
      }),
      dispatch,
    );
    dispatch(matchActions.saveConsultNPSForms(response.data));
  };

export const getExtendedMatch: AsyncActionCreator =
  (matchId: number) => async (dispatch, getState) => {
    dispatch(matchActions.fetchingExtendedMatch({ [matchId]: true }));
    dispatch(getMspsForMatch(matchId));
    dispatch(getConflictsOfInterestForMatch(matchId));
    try {
      const response: AxiosResponse<ExtendedMatch> = await wrapApiCall(
        api.get(`/ehr/matches/v2/${matchId}/extended/`),
        dispatch,
      );

      const match = response.data;
      if (match.selected_fit) {
        const { clinicianMap } = getState().clinicians;
        const selectedFitClinician =
          clinicianMap[match.selected_fit.clinician.id];
        match.selected_fit.clinician = {
          ...match.selected_fit.clinician,
          ...selectedFitClinician,
        };
      }
      dispatch(matchActions.saveExtendedMatch(match));
      dispatch(matchActions.fetchingExtendedMatch({ [matchId]: false }));
    } catch (e) {
      dispatch(matchActions.fetchingExtendedMatch({ [matchId]: false }));
    }
  };

export const setMatchPriority: AsyncActionCreator =
  (matchId: number, priority: MatchPriority) => (dispatch) => {
    dispatch(matchActions.setMatchPriority({ matchId, priority }));
    return wrapApiCall(
      api.post("/ehr/matches/v2/set_priority/", {
        id: matchId,
        priority,
      }),
      dispatch,
    );
  };

export const setMatchReturningToSameClinician: AsyncActionCreator =
  (matchId: number, returningToSameClinician: boolean) => (dispatch) => {
    dispatch(
      matchActions.setMatchReturningToSameClinician({
        matchId,
        returningToSameClinician,
      }),
    );
    return wrapApiCall(
      api.post("/ehr/matches/v2/set_returning_to_same_clinician/", {
        id: matchId,
        returningToSameClinician,
      }),
      dispatch,
    );
  };

export const getClinicianSlots: AsyncActionCreator =
  (
    clinicianId: number,
    startWeek: number,
    endWeek: number,
    yearFrom: number,
    yearTo: number,
  ) =>
  async (dispatch) => {
    const response = await wrapApiCall(
      api.get(
        `/ehr/panels/${clinicianId}/getClinicianSlots/` +
          `?start_year=${yearFrom}` +
          `&end_year=${yearTo}` +
          `&start_week=${startWeek}` +
          `&end_week=${endWeek}`,
      ),
      dispatch,
    );
    dispatch(
      matchActions.setClinicianSlotInfo({
        clinicianId,
        clinicianSlotInfo: response.data,
      }),
    );
  };

export const getClinicianTags: AsyncActionCreator =
  (clinicianId: number) => async (dispatch) => {
    dispatch(matchActions.setLoadingClinicianTags(true));
    try {
      const response = await wrapApiCall(
        api.get(`/ehr/clinicians/${clinicianId}/tags`),
        dispatch,
      );
      dispatch(matchActions.saveClinicianTags(response.data));
      dispatch(matchActions.setLoadingClinicianTags(false));
    } catch (e) {
      dispatch(matchActions.setLoadingClinicianTags(false));
    }
  };

export const createClinicianTag: AsyncActionCreator =
  (
    clinicianId: number,
    tagId: number,
    weight: -1 | 1 | 2 | 4,
    prefer_not_to_treat: boolean = false,
    metadata: { is_specialty?: boolean } | null = null,
  ) =>
  async (dispatch) => {
    dispatch(matchActions.setLoadingClinicianTags(true));
    try {
      const response = await wrapApiCall(
        api.post(`/ehr/clinicians/${clinicianId}/tags/${tagId}`, {
          weight,
          prefer_not_to_treat,
          metadata,
        }),
        dispatch,
      );
      dispatch(matchActions.createClinicianTag(response.data));
      message.success("Tag successfully created!");
      dispatch(matchActions.setLoadingClinicianTags(false));
    } catch (e) {
      message.error("Tag was not successfully created -- try refreshing");
      dispatch(matchActions.setLoadingClinicianTags(false));
    }
  };

export const patchClinicianTag: AsyncActionCreator =
  (
    clinicianId: number,
    tagId: number,
    weight: -1 | 1 | 2 | 4,
    prefer_not_to_treat: boolean,
    metadata: { is_specialty?: boolean } | null = null,
  ) =>
  async (dispatch) => {
    dispatch(matchActions.setLoadingClinicianTags(true));
    try {
      await wrapApiCall(
        api.patch(`/ehr/clinicians/${clinicianId}/tags/${tagId}`, {
          weight,
          prefer_not_to_treat,
          metadata,
        }),
        dispatch,
      );
      dispatch(
        matchActions.patchClinicianTag({
          clinicianId,
          tagId,
          weight,
          prefer_not_to_treat,
        }),
      );
      message.success("Tag successfully updated!");
      dispatch(matchActions.setLoadingClinicianTags(false));
    } catch (e) {
      message.error("Tag update was not successful -- try refreshing");
      dispatch(matchActions.setLoadingClinicianTags(false));
    }
  };

export const deleteClinicianTag: AsyncActionCreator =
  (clinicianId: number, tagId: number) => async (dispatch) => {
    dispatch(matchActions.deleteClinicianTag({ clinicianId, tagId }));
    try {
      await wrapApiCall(
        api.delete(`/ehr/clinicians/${clinicianId}/tags/${tagId}`),
        dispatch,
      );
      dispatch(
        matchActions.deleteClinicianTag({
          clinicianId,
          tagId,
        }),
      );
      message.success("Tag successfully removed!");
      dispatch(getClinicianTags(clinicianId));
      dispatch(matchActions.setLoadingClinicianTags(false));
    } catch (e) {
      message.error("Unable to remove tag -- try refreshing");
      dispatch(matchActions.setLoadingClinicianTags(false));
    }
  };

export const getMspsForMatch: AsyncActionCreator =
  (matchId: number) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/${matchId}/slot-preferences`;
      const request: AxiosResponse<MatchSlotPreference[]> = await wrapApiCall(
        api.get(url),
        dispatch,
      );
      dispatch(
        matchActions.setMatchSlotPreferences({ [matchId]: request.data || [] }),
      );
    } catch (e) {
      console.error("Unable to get Match Slot Preferences");
    }
  };

export const getMspsForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/slot-preferences/`;
      const request: AxiosResponse<MatchSlotPreference[]> = await wrapApiCall(
        api.put(url, { matchIds }),
        dispatch,
      );
      const msps: IdMap<MatchSlotPreference[]> = {};
      matchIds.forEach((id) => (msps[id] = []));
      request.data.forEach((msp) => {
        const matchId = msp.match as number;
        if (!(matchId in msps)) {
          msps[matchId] = [];
        }
        msps[matchId].push(msp);
      });
      dispatch(matchActions.setMatchSlotPreferences(msps));
    } catch (e) {
      console.error("Unable to get Match Slot Preferences");
    }
  };

export const getRelatedModelsForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    chunk(matchIds, 500).forEach((idArr: number[]) => {
      dispatch(getMspsForMatches(idArr));
      dispatch(getClinicPreferencesForMatches(idArr));
      dispatch(getFitsForMatches(idArr));
      dispatch(getTagsForMatches(idArr));
      dispatch(getConflictsOfInterestForMatches(idArr));
    });
  };

export const getClinicPreferencesForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/clinic_preferences/`;
      const request: AxiosResponse<ClinicPreference[]> = await wrapApiCall(
        api.put(url, { matchIds }),
        dispatch,
      );
      const cps: IdMap<ClinicPreference[]> = {};
      matchIds.forEach((id) => (cps[id] = []));
      request.data.forEach((cp) => {
        const matchId = cp.match_id;
        if (!(matchId in cps)) {
          cps[matchId] = [];
        }
        cps[matchId].push(cp);
      });
      dispatch(matchActions.setMatchClinicPreferences(cps));
    } catch (e) {
      console.error("Unable to get Match Clinic Preferences");
    }
  };

export const getConflictsOfInterestForMatch: AsyncActionCreator =
  (matchId: number) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/${matchId}/conflicts_of_interest/`;
      const request: AxiosResponse<APIConflictOfInterest[]> = await wrapApiCall(
        api.get(url),
        dispatch,
      );
      dispatch(
        matchActions.setMatchConflictsOfInterest({
          [matchId]: request.data || [],
        }),
      );
    } catch (e) {
      console.error("Unable to get Match Conflicts of Interest");
    }
  };

export const getConflictsOfInterestForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/conflicts_of_interest_bulk/`;
      const request: AxiosResponse<APIConflictOfInterest[]> = await wrapApiCall(
        api.put(url, { matchIds }),
        dispatch,
      );
      const cois: IdMap<APIConflictOfInterest[]> = {};
      matchIds.forEach((id) => (cois[id] = []));
      request.data.forEach((coi) => {
        const matchId = coi.match_id;
        if (!(matchId in cois)) {
          cois[matchId] = [];
        }
        cois[matchId].push(coi);
      });
      dispatch(matchActions.setMatchConflictsOfInterest(cois));
    } catch (e) {
      console.error("Unable to get Match Conflicts of Interest");
    }
  };

export const getFitsForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/fits/`;
      const request: AxiosResponse<Fit[]> = await wrapApiCall(
        api.put(url, { matchIds }),
        dispatch,
      );
      const fits: IdMap<Fit[]> = {};
      matchIds.forEach((id) => (fits[id] = []));
      request.data.forEach((fit) => {
        const matchId = fit.match;
        if (!(matchId in fits)) {
          fits[matchId] = [];
        }
        fits[matchId].push(fit);
      });
      dispatch(matchActions.setMatchFits(fits));
    } catch (e) {
      console.error("Unable to get Match Fits");
    }
  };

type ApiMatchTag = MatchTag & {
  match_id: number;
};
interface MatchTagResponse {
  consult: ApiMatchTag[];
  match: ApiMatchTag[];
}

export const getTagsForMatches: AsyncActionCreator =
  (matchIds: number[]) => async (dispatch) => {
    try {
      const url = `ehr/matches/v2/tags/`;
      const request: AxiosResponse<MatchTagResponse> = await wrapApiCall(
        api.put(url, { matchIds }),
        dispatch,
      );
      const matchTags: IdMap<MatchTag[]> = {};
      matchIds.forEach((id) => {
        matchTags[id] = [];
      });
      request.data.match.forEach((tag) => {
        const matchId = tag.match_id;
        if (!(matchId in matchTags)) {
          matchTags[matchId] = [];
        }
        matchTags[matchId].push(tag);
      });
      dispatch(matchActions.setConsultTags(matchTags));
      dispatch(matchActions.setMatchTags(matchTags));
    } catch (e) {
      console.error("Unable to get Match Tags");
    }
  };

export type ApiClinicianTag = ClinicianMatchTag & {
  clinician_id: number;
};

export const setNotificationStatus: AsyncActionCreator =
  (query: NotificationFields) => async (dispatch) => {
    const url = "api/notifications/v1/set_resend_ok/";
    await wrapApiCall(api.put(url, query), dispatch);
  };

export const getMatchSuggestions: AsyncActionCreator =
  (
    restrictions: AQMRestrictions,
    tuningParams: AQMTuningParams,
    highAcuityOnly: boolean,
    forceRefresh?: boolean,
  ) =>
  async (dispatch) => {
    dispatch(matchActions.setLoadingMatchSuggestions(true));
    try {
      const url = `ehr/suggest-match-pairings/`;
      const request: AxiosResponse<AQMResponse> = await wrapApiCall(
        api.post(url, { restrictions, tuningParams, forceRefresh, highAcuityOnly }),
        dispatch,
      );
      if (request.data.errors.length) {
        throw request.data.errors;
      }
      dispatch(matchActions.setAqmScores(request.data.scores));
      dispatch(matchActions.setMatchSuggestions(request.data.suggestions));
      dispatch(
        matchActions.setQueueConditionID(request.data.queue_condition_id),
      );
      dispatch(matchActions.setQueueConditionPaths(request.data.paths))
      dispatch(matchActions.setResultsId(request.data.id));
    } catch (e) {
      console.error("Unable to get Match Suggestions", e);
      dispatch(matchActions.setLoadingMatchSuggestions(false));
    }
  };

export const clearMatchSuggestions: AsyncActionCreator = () => (dispatch) => {
  dispatch(matchActions.setMatchSuggestions([]));
  dispatch(matchActions.setQueueConditionID(null));
  dispatch(matchActions.setQueueConditionPaths({}));
  dispatch(matchActions.setResultsId(0))
};

export const setAqmTuningParams: AsyncActionCreator =
  (params?: AQMTuningParams) => async (dispatch) => {
    if (params) {
      dispatch(matchActions.setAqmTuningParams(params));
    } else {
      try {
        const url = `ehr/aqmTuningParams/`;
        const request: AxiosResponse<AQMTuningParams> = await wrapApiCall(
          api.get(url),
          dispatch,
        );
        dispatch(matchActions.setAqmTuningParams(request.data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const saveAqmTuningParams: AsyncActionCreator =
  (params: AQMTuningParams) => async (dispatch) => {
    const url = `ehr/aqmTuningParams/`;
    const request: AxiosResponse<AQMTuningParams> = await wrapApiCall(
      api.post(url, { params }),
      dispatch,
    );
    dispatch(matchActions.setAqmTuningParams(request.data));
  };

export const saveAqmTuningTestParams: AsyncActionCreator =
  (name: string, params: AQMTuningParams) => async (dispatch) => {
    const url = `ehr/aqmTuningTestParams/`;
    await wrapApiCall(api.post(url, { name, params }), dispatch);
  };

export const openAqmScorecard: AsyncActionCreator = () => async (dispatch) => {
  dispatch(matchActions.setAqmScorecardModalIsOpen(true));
  dispatch(matchActions.setLoadingAqmScorecard(true));
  const url = `ehr/aqmScorecard/`;
  const request: AxiosResponse<object> = await wrapApiCall(
    api.get(url),
    dispatch,
  );
  dispatch(matchActions.setAqmScorecard(request.data));
  dispatch(matchActions.setLoadingAqmScorecard(false));
};
