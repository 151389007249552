import { ContactsOutlined, CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Select as DeprecatedAntdSelect,
  Divider,
  Popover,
  Spin,
  Checkbox,
  Typography,
} from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  ClinicPreference,
  Fit,
  BasicMatch,
  MatchSlotPreference,
  APIConflictOfInterest,
  MatchPriority,
  CouplesMatch,
} from "../../../../api/types";
import { copyToClipboard, toMap, getMatchTimestamp } from "../../../_helpers";
import { Title, Pad } from "../../../_layout/display";
import { Row, Flex } from "../../../_layout/Flex";
import ClinicianItem from "../../../_shared/ClinicianItem";
import ClinicianStatus from "../../../_shared/ClinicianStatus";
import ClinicPreferenceList from "../../../_shared/ClinicPreferenceList";
import { matchOperations } from "../../../../state/models/matches";
import Mangle from "../../../_shared/Mangle";
import FitList from "../../FitList";
import {
  useUserHasAnyPermissions,
  userCanAccessHealthie,
} from "../../../_helpers/permissions";
import DisplayMatchSlotPreferences from "../DisplayMatchSlotPreferences";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { isEmpty } from "lodash-es";
import QueueNote from "../QueueNote";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import MatchNote from "../MatchNote";
import { MatchNoteInput } from "../../ShoppingCart";
import MatchAssignment from "../MatchAssignment";
import * as colors from "../../../../assets/colors";
import moment from "moment-timezone";
import { TIMEZONE } from "@/app/my-clients/utils";
import { AQMScoreTable } from "../aqm-score-table/aqm-score-table";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
interface Props {
  fitFilter?: { [id: number]: { couples: boolean; individual: boolean } };
  matchId: number;
}

function MatchQueueItem(props: Props) {
  const [
    clinicianMap,
    clinicPrefs,
    matchFits,
    matchTags,
    msps,
    myMatchesMap,
    queuedMatches,
    conflictsOfInterest,
    fetchingMyMatches,
  ] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
    state.matches.matchClinicPreferencesMap[props.matchId],
    state.matches.matchFitsMap[props.matchId] || [],
    state.matches.matchTagsMap[props.matchId] || [],
    state.matches.matchSlotPreferenceMap[props.matchId],
    state.matches.myMatchesMap,
    state.matches.queuedMatches,
    state.matches.matchConflictsOfInterestMap[props.matchId] || [],
    state.matches.fetchingMyMatches,
  ]);
  const cuser = React.useContext(CurrentUserContext);

  const loadingRelatedModels =
    matchFits === undefined ||
    matchTags === undefined ||
    (msps === undefined && clinicPrefs === undefined) ||
    fetchingMyMatches;

  const dispatch = useDispatch();
  const setMatchPriority = (matchId: number, priority: MatchPriority) =>
    dispatch(matchOperations.setMatchPriority(matchId, priority));
  const setMatchReturningToSameClinician = (
    matchId: number,
    returningToSameClinician: boolean,
  ) =>
    dispatch(
      matchOperations.setMatchReturningToSameClinician(
        matchId,
        returningToSameClinician,
      ),
    );

  const openMatchModal = (match: BasicMatch) =>
    dispatch(
      matchmakerOperations.openMatchmaker({
        match,
        rematch: null,
        consult: null,
      }),
    );

  const matchClick = (matchId: number) => {
    const match =
      queuedMatches.find((m) => m.id === matchId) || myMatchesMap[matchId];
    if (match) {
      if (isCouplesMatch) {
        return dispatch(
          matchmakerOperations.openCouplesMatchmaker({
            match,
            rematch: null,
          }),
        );
      } else {
        return openMatchModal(match);
      }
    }
    return null;
  };

  const isMatchingAdmin = useUserHasAnyPermissions([
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);
  const isClinicalLeader = useUserHasAnyPermissions([
    "IsClinicalLeader",
    "IsSuperUser",
  ]);
  const isSuperUser = useUserHasAnyPermissions(["IsSuperUser"]);
  const displayConflictOfInterest = useCallback(
    (coi: APIConflictOfInterest, idx: number) => {
      if (coi.client !== null) {
        return (
          <div key={idx} style={coiStyle}>
            <Mangle>
              {coi.client.first_name} {coi.client.last_name}
            </Mangle>{" "}
            <i style={{ color: colors.$greyText }}>Client</i>
          </div>
        );
      } else if (coi.hq_member !== null) {
        return (
          <div key={idx} style={coiStyle}>
            {coi.hq_member.full_name}{" "}
            <i style={{ color: colors.$greyText }}>Hq Member</i>
          </div>
        );
      } else {
        const clinician = clinicianMap[coi.clinician_id];
        const clinicianName = clinician
          ? `${clinician.first_name} ${clinician.last_name}`
          : `Inactive`;
        return (
          <div key={idx} style={coiStyle}>
            {clinicianName} <i style={{ color: colors.$greyText }}>Clinician</i>
          </div>
        );
      }
    },
    [clinicianMap],
  );

  const match =
    queuedMatches.find((m) => m.id === props.matchId) ||
    myMatchesMap[props.matchId];
  if (!match) {
    return null;
  }

  const isCouplesMatch = "couple" in match && match.couple !== null;

  const consultDate = moment(getMatchTimestamp(match))
    .tz(TIMEZONE(cuser))
    .format("MMMM Do, YYYY");

  const filteredFits = matchFits.filter(
    (fit) =>
      props.fitFilter === undefined ||
      (props.fitFilter[fit.clinician] &&
        props.fitFilter[fit.clinician][match.service_type]),
  );

  const clinicianIds = filteredFits.map((fit) => fit.clinician);

  const cadenceText = match.is_biweekly_fit
    ? match.cadence_pref_strength === "strong"
      ? "Biweekly only"
      : "Prefer biweekly"
    : match.cadence_pref_strength === "strong"
    ? "Weekly only"
    : "Prefer weekly";

  const fieldForCoupleOrIndividual = (
    match: BasicMatch | CouplesMatch,
    field: string,
  ) => {
    if (isCouplesMatch) {
      return match.couple?.clientA[field];
    } else {
      return match?.client[field];
    }
  };

  return (
    <>
      <QueueNote queueNote={match.queue_note} />
      <Pad>
        <Row layout="start stretch" style={{ marginBottom: "5px" }}>
          <Flex>
            <p>
              <Typography.Text>
                Methodology version: {match.methodology_version}
              </Typography.Text>
            </p>
            <Typography.Text style={{ color: colors.$greyText }}>
              Match ID: {match.id} | Client ID: {match.client.id}
            </Typography.Text>
            {match.is_currently_awaiting_eligibility_benefits_verification ? (
              <Title size="xs" style={{ color: colors.$red }}>
                Client is awaiting eligibility benefits verification and cannot
                be matched.
              </Title>
            ) : null}
            {match.client.payers.map((p) => (
              <Title
                size="xs"
                key={p.id}
                style={{ margin: 0, color: colors.$kpBlue }}
              >
                {p.payer.display_name} Client
              </Title>
            ))}
            {match.is_medicare && (
              <Title size="xs" style={{ margin: 0, color: colors.$kpBlue }}>
                MediCare Client
              </Title>
            )}
            {match.is_medical && (
              <Title size="xs" style={{ margin: 0, color: colors.$kpBlue }}>
                MediCal Client
              </Title>
            )}
            <Title size="xs" margin="0px">
              Conflict of Interest (Client Profile):&nbsp;
            </Title>
            <div style={coiStyle}>
              {match.conflict_of_interest ? (
                match.conflict_of_interest
              ) : (
                <i>Question not answered.</i>
              )}
            </div>
            {conflictsOfInterest.length > 0 && (
              <>
                <Title size="xs" margin="0px">
                  Conflicts of Interest:
                </Title>
                {conflictsOfInterest.length === 0 && (
                  <div style={coiStyle}>
                    <i>No conflicts.</i>
                  </div>
                )}
                {conflictsOfInterest.map(displayConflictOfInterest)}
              </>
            )}
            {!isCouplesMatch && (
              <div>
                <Title size="xs" margin="0px">
                  Delivery Preferences:&nbsp;
                </Title>
                <div style={coiStyle}>
                  {match.delivery_preference === "hybrid"
                    ? "Client prefers hybrid therapy"
                    : match.delivery_preference === "remote"
                    ? "Client prefers ongoing teletherapy"
                    : "Client has no delivery preference"}
                </div>
                {match.delivery_preference !== "hybrid" && (
                  <div style={coiStyle}>
                    Consult Clinician{" "}
                    {match.clinician_approves_ot
                      ? "approves ongoing teletherapy"
                      : "does not approve ongoing teletherapy"}
                  </div>
                )}
              </div>
            )}
          </Flex>
          {!isCouplesMatch && (
            <Flex
              style={{
                borderLeft: "1px solid rgb(232, 232, 232)",
                paddingLeft: "10px",
              }}
            >
              <Row layout="start center" style={{ marginBottom: "10px" }}>
                <Title size="xs" margin="0px">
                  Consult Clinician:&nbsp;
                </Title>
                <Flex>
                  <ClinicianItem
                    clinicianId={
                      match.consult
                        ? match.consult.clinician
                        : match.created_by_clinician || 0
                    }
                    hideClinic={true}
                  />
                </Flex>
              </Row>
              {isMatchingAdmin && (
                <Row layout="start center" style={{ marginBottom: "10px" }}>
                  <Title size="xs" margin="0px">
                    Assignee:&nbsp;
                  </Title>
                  <Flex>
                    <MatchAssignment basicMatch={match} />
                  </Flex>
                </Row>
              )}
              {match.match_after !== null && (
                <Row layout="start center" style={{ marginBottom: "10px" }}>
                  <Title size="xs" margin="0px">
                    Delay Matching Until:&nbsp;
                  </Title>
                  <Flex>{moment(match.match_after).format("LL")}</Flex>
                </Row>
              )}
              <Row layout="start center" style={{ marginBottom: "10px" }}>
                <Title size="xs" margin="0px">
                  {cadenceText}
                </Title>
              </Row>
            </Flex>
          )}
        </Row>
        {!loadingRelatedModels && (
          <>
            <MatchLocationPreferences
              match={match}
              msps={msps}
              clinicPrefs={clinicPrefs}
              clinicianIds={clinicianIds}
            />
            <Title margin="0px" size="xs">
              Fit List
            </Title>
            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <FitList<Fit>
                maxScore={match.max_score}
                comparisonTagMap={toMap(matchTags)}
                serviceType={match.service_type}
                fits={filteredFits}
                additionalContent={(f) => <MatchNoteInput matchId={f.match} />}
                version={match.methodology_version}
                fitAction={(f) => {
                  const fit = f as Fit;
                  return (
                    <Row layout="flex-end center" style={{ width: "260px" }}>
                      <ClinicianStatus
                        clinicianId={fit.clinician}
                        showCouplesAvailability={
                          match.service_type === "couples"
                        }
                      />
                    </Row>
                  );
                }}
              />
            </div>
          </>
        )}
        {loadingRelatedModels && (
          <Pad style={{ textAlign: "center" }}>
            <Spin />
          </Pad>
        )}
      </Pad>
      <MatchNote matchNote={match.match_note} />
      {!loadingRelatedModels && (
        <>
          <Divider style={{ margin: "0px" }} />
          <Row layout={"start center"} style={{ padding: "15px" }}>
            {(isClinicalLeader || isMatchingAdmin) && (
              <DeprecatedAntdSelect<MatchPriority | "null">
                style={{ marginRight: "15px", width: "140px" }}
                value={match.priority || "null"}
                onChange={(value: MatchPriority | "null") =>
                  setMatchPriority(match.id, value === "null" ? null : value)
                }
              >
                <DeprecatedAntdSelect.Option
                  key="OPTION__regular_priority"
                  value={"null"}
                  title={"null"}
                >
                  Regular Priority
                </DeprecatedAntdSelect.Option>
                <DeprecatedAntdSelect.Option
                  key="OPTION__high_acuity"
                  value={"high_acuity"}
                  title={"high_acuity"}
                >
                  High Acuity
                </DeprecatedAntdSelect.Option>
                <DeprecatedAntdSelect.Option
                  key="OPTION__high_priority"
                  value={"high_priority"}
                  title={"high_priority"}
                >
                  High Priority
                </DeprecatedAntdSelect.Option>
              </DeprecatedAntdSelect>
            )}
            {(isClinicalLeader || isMatchingAdmin) && (
              <Checkbox
                checked={match.returning_to_same_clinician}
                onChange={(e) =>
                  setMatchReturningToSameClinician(match.id, e.target.checked)
                }
              >
                Returning To Same Clinician
              </Checkbox>
            )}
            <Flex />
            {isSuperUser && !isCouplesMatch && match.client.healthie_id && (
              <Button
                style={{ marginRight: "15px" }}
                href={
                  "https://twochairs.gethealthie.com/users/" +
                  match.client.healthie_id
                }
                icon={<ContactsOutlined />}
                target="_blank"
              >
                Healthie Profile
              </Button>
            )}
            <Button
              style={{ marginRight: "15px" }}
              onClick={() =>
                copyToClipboard(fieldForCoupleOrIndividual(match, "email"))
              }
            >
              <CopyOutlined /> Client Email
            </Button>
            <Popover
              trigger="click"
              title={
                <Mangle>
                  {isCouplesMatch
                    ? `${match.couple!.clientA.first_name} ${
                        match.couple!.clientA.last_name
                      }`
                    : `${match.client.first_name} ${match.client.last_name}`}
                </Mangle>
              }
              content={
                <>
                  {isSuperUser && (
                    <>
                      <div>
                        <Mangle>
                          <strong>
                            {isCouplesMatch ? "Couple" : "Client"} ID:
                          </strong>{" "}
                          {isCouplesMatch
                            ? match.couple!.chroniclerId
                            : match.client.id}
                        </Mangle>
                      </div>
                      <div>
                        <Mangle>
                          <strong>Match ID:</strong> {match.id}
                        </Mangle>
                      </div>
                    </>
                  )}
                  <div>
                    <Mangle>
                      <strong>Phone:</strong>{" "}
                      {fieldForCoupleOrIndividual(match, "phone_number")}
                    </Mangle>
                  </div>
                  <div>
                    <Mangle>
                      <strong>Email:</strong>{" "}
                      {fieldForCoupleOrIndividual(match, "email")}
                    </Mangle>
                  </div>
                  <div>
                    <Mangle>
                      <strong>Consult Date:</strong> {consultDate}
                    </Mangle>
                  </div>
                </>
              }
            >
              <Button style={{ marginRight: "15px" }}>
                Client Information
              </Button>
            </Popover>
            <Button onClick={() => matchClick(match.id)} type={"primary"}>
              Edit Match
            </Button>
          </Row>
          <AQMScoreTable matchId={match.id} />
        </>
      )}
    </>
  );
}

export default MatchQueueItem;

const MatchLocationPreferences = ({
  match,
  msps,
  clinicPrefs,
  clinicianIds,
}: {
  match: BasicMatch;
  msps: MatchSlotPreference[];
  clinicPrefs: ClinicPreference[];
  clinicianIds: number[];
}) => {
  if (isEmpty(msps) && isEmpty(clinicPrefs)) {
    return null;
  }

  if (!isEmpty(msps)) {
    return (
      <DisplayMatchSlotPreferences match={match} clinicianIds={clinicianIds} />
    );
  } else {
    return (
      <ClinicPreferenceList
        clinicPreferences={clinicPrefs.filter(
          (cp: ClinicPreference) => cp.preference_level === "match",
        )}
      />
    );
  }
};

const coiStyle = { margin: "0 10px 10px" };
