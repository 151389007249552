import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  matchmakerActions,
  matchmakerOperations,
  matchmakerUtils,
} from "../../../../state/models/matchmaker";

import { Title } from "../../../_layout/display";
import { Spin, Button } from "antd";
import FitList from "../../FitList";
import { FitSuggestion, IdMap, MatchTag } from "../../../../api/types";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import {
  TagWeight,
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
} from "../../../../api/constants";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { HelperTextBox } from "../../MatchingV2/_components/helper-text-box";

export default function ClinicianFits() {
  const [
    loading,
    matchAgeRange,
    maxScore,
    tagIdsByWeight,
    tagMap,
    starredTagIds,
    clinicianMap,
    rematchToClinicianId,
    methodologyVersion,
  ] = useShallowEqualSelector((state) => [
    state.matchmaker.loading.potentialFits,
    state.matchmaker.matchAgeRange,
    state.matchmaker.draft.max_score,
    state.matchmaker.matchTagMap,
    state.matches.tagMap,
    state.matchmaker.starredTagIds,
    state.clinicians.clinicianMap,
    state.matchmaker.rematchToClinicianId,
    state.matchmaker.methodologyVersion,
  ]);

  const {
    potentialFits,
    fits,
    excludedFits,
    locationMismatchFits,
    conflictOfInterestFits,
    outOfAgeRangeFits,
    nonKpFits,
    nonMedicalFits,
    nonMedicareFits,
    credentialMismatchFits,
    wheelRestrictionsFits,
    clientAwaitingEBFits,
  } = useShallowEqualSelector(matchmakerUtils.getFilteredFits);

  const [cliniciansTotal, rematchToClinicianFullName] = useMemo(() => {
    const clinician =
      (rematchToClinicianId && clinicianMap[rematchToClinicianId]) || null;
    return [
      Object.values(clinicianMap).length,
      clinician ? `${clinician.first_name} ${clinician.last_name}` : null,
    ];
  }, [clinicianMap, rematchToClinicianId]);

  const matchTagMap: IdMap<MatchTag> = useMemo(() => {
    const map: IdMap<MatchTag> = {};
    ([NEEDS_TAG_WEIGHT, BENEFIT_TAG_WEIGHT] as TagWeight[]).forEach(
      (weight) => {
        tagIdsByWeight[weight].forEach((tagId: number) => {
          map[tagId] = {
            ...tagMap[tagId],
            weight,
            starred: starredTagIds.includes(tagId),
          };
        });
      },
    );
    return map;
  }, [tagIdsByWeight, tagMap, starredTagIds]);

  const dispatch = useDispatch();
  const setClinicianExclusion = (clinicianId: number, excluded: boolean) => {
    dispatch(
      matchmakerActions.setClinicianExclusion({ clinicianId, excluded }),
    );
    dispatch(matchmakerOperations.getPotentialFits());
  };

  const ExcludedFitList = (props: {
    fits: FitSuggestion[];
    displayName: string;
    fitAction?: (fit: FitSuggestion) => React.ReactElement;
  }) => {
    const { fits, displayName, fitAction } = props;
    return fits.length > 0 ? (
      <>
        {fits.length > 1 && (
          <Title size="small" margin="0px">
            {fits.length} clinicians are {displayName}
          </Title>
        )}
        {fits.length === 1 && (
          <Title size="small" margin="0px">
            1 clinician is {displayName}
          </Title>
        )}
        <FitList<FitSuggestion>
          maxScore={maxScore}
          comparisonTagMap={matchTagMap}
          fits={fits}
          version={methodologyVersion}
          fitAction={fitAction}
          serviceType="individual"
        />
      </>
    ) : null;
  };

  return loading ? (
    <Spin
      tip="Loading fits ..."
      style={{ width: "100%", margin: "50px auto" }}
    />
  ) : (
    <>
      {fits.length > 1 && (
        <Title size="small" margin="0px">
          {fits.length} clinicians are a great fit!
        </Title>
      )}
      {fits.length === 1 && (
        <Title size="small">1 clinician is a great fit!</Title>
      )}
      {fits && fits.length < 30 && (
        <HelperTextBox
          primaryText="Fit list low"
          secondaryText="Aim for 50 fits if client is high acuity. Otherwise, aim for 30. Try changing delivery preference, increasing availability, or adapt Needs tags to generate more fits."
          color="#BD4B00"
          background="#FFF1E7"
        />
      )}
      {rematchToClinicianFullName && fits.length < 1 && (
        <div>
          <Title size="small" margin="10px">
            {rematchToClinicianFullName} is not a Fit at this time
          </Title>
          <div style={{ marginBottom: "15px" }}>
            Please update the clinical information for this Match, or select a
            different Rematch Reason.
          </div>
        </div>
      )}
      <FitList<FitSuggestion>
        maxScore={maxScore}
        comparisonTagMap={matchTagMap}
        fits={fits}
        version={methodologyVersion}
        fitAction={(fit: FitSuggestion) => (
          <Button
            style={{ border: "none", background: "none", boxShadow: "none" }}
            danger
            {...{
              size: "small",

              onClick: (e) => {
                e.stopPropagation();
                setClinicianExclusion(fit.clinician.id, true);
              },
            }}
          >
            <MinusCircleFilled />
          </Button>
        )}
        flaggedFits={outOfAgeRangeFits}
        serviceType="individual"
      />
      <ExcludedFitList
        fits={excludedFits}
        displayName="removed from the Fit List"
        fitAction={(fit: FitSuggestion) => (
          <Button
            style={{ border: "none", background: "none", boxShadow: "none" }}
            {...{
              size: "small",
              onClick: (e) => {
                e.stopPropagation();
                setClinicianExclusion(fit.clinician.id, false);
              },
            }}
          >
            <PlusCircleFilled style={{ color: "rgb(187, 204, 102)" }} />
          </Button>
        )}
      />
      <ExcludedFitList
        fits={locationMismatchFits}
        displayName="not a fit for location preferences"
      />
      <ExcludedFitList
        fits={conflictOfInterestFits}
        displayName="a Conflict of Interest"
      />
      {matchAgeRange?.filter_clinical_fits && (
        <ExcludedFitList
          fits={outOfAgeRangeFits}
          displayName="out of Age Range"
        />
      )}
      <ExcludedFitList
        fits={clientAwaitingEBFits}
        displayName="blocked due to client awaiting E&B verification"
      />
      <ExcludedFitList
        fits={nonKpFits}
        displayName="not Kaiser Permanente enabled"
      />
      <ExcludedFitList
        fits={nonMedicalFits}
        displayName="not Medical enabled"
      />
      <ExcludedFitList
        fits={nonMedicareFits}
        displayName="not Medicare enabled"
      />
      <ExcludedFitList
        fits={credentialMismatchFits}
        displayName="missing the required credential"
      />
      <ExcludedFitList
        fits={wheelRestrictionsFits}
        displayName="Wheel and client is restricted"
      />
      {!loading && (
        <Title size="small" margin="0px">
          {cliniciansTotal - potentialFits.length} clinician
          {cliniciansTotal - potentialFits.length === 1 ? "" : "s"} not
          returned.
        </Title>
      )}
    </>
  );
}
