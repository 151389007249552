import { message } from "antd";
import { AxiosResponse } from "axios";
import { chunk } from "lodash-es";
import moment, { Moment } from "moment";

import api, { wrapApiCall } from "../../../api";
import {
  ClientExtended,
  ClinicianHold,
  ClinicianResponse,
  ClinicianSlot,
  CoupleExtended,
  CouplesMatchAvailability,
  ManualCapacity,
  NewClinicianHold,
  PendingMatch,
  Procedure,
  ScheduleItem,
} from "../../../api/types";
import { alphabetizeBy } from "../../../app/_helpers";
import { GET_LATEST_MATCHING_SESSION } from "../../../app/clinicians/ClinicianDetail/History";
import { matchOperations } from "../matches";
import { AsyncActionCreator } from "../types";
import { clinicianActions, ConsentSurveyModalData } from "./";

export const getActiveClinicians: AsyncActionCreator =
  () => async (dispatch) => {
    const response = await wrapApiCall(
      api.get("/ehr/clinicians/active/"),
      dispatch,
    );
    // Process response data
    dispatch(
      clinicianActions.loadClinicians(
        response.data.map((clinician: ClinicianResponse) => {
          return {
            ...clinician,
          };
        }),
      ),
    );
  };

export const getClinicianData: AsyncActionCreator =
  (setLoading: boolean = true) =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(clinicianActions.fetchClinicians());
    }
    dispatch(clinicianActions.setLastUpdated());
    const response = await wrapApiCall(
      api.get("/ehr/clinicians/active/"),
      dispatch,
    );
    // Process response data
    dispatch(
      clinicianActions.loadClinicians(
        response.data.map((clinician: ClinicianResponse) => {
          return {
            ...clinician,
          };
        }),
      ),
    );
    const clinicianIds: number[] = response.data
      .sort((a: ClinicianResponse, b: ClinicianResponse) =>
        alphabetizeBy(a, b, "first_name"),
      )
      .map((clinician: ClinicianResponse) => clinician.id);
    // Begin Fetching schedule data
    wrapApiCall(
      api.post("/ehr/clinicians/schedules/", { clinicianIds }),
      dispatch,
    ).then((res: AxiosResponse<ScheduleItem[]>) => {
      dispatch(clinicianActions.loadSchedules(res.data));
    });
    dispatch(getPendingMatches());
    dispatch(getRecentCapacities());
    if (setLoading) {
      dispatch(clinicianActions.completedClinicianLoad());
    }
  };

export const getPendingMatches: AsyncActionCreator = () => async (dispatch) => {
  try {
    const res: AxiosResponse<PendingMatch[]> = await wrapApiCall(
      api.get("/ehr/matches/v2/pending/"),
      dispatch,
    );
    const matches = res.data;
    dispatch(clinicianActions.loadPendingMatches_v2(matches));
  } catch (e) {
    console.error("Error getting Pending Matches.");
  }
};

export const getActiveHolds: AsyncActionCreator =
  (setLoading: boolean = true) =>
  async (dispatch) => {
    try {
      if (setLoading) {
        dispatch(clinicianActions.fetchActiveHolds());
      }

      const response = await wrapApiCall(
        api.get("/ehr/clinicianHolds/active/"),
        dispatch,
      );

      dispatch(clinicianActions.loadActiveHolds(response.data));
    } catch (e) {
      console.error(e);
    }
  };

export const addHold: AsyncActionCreator =
  (hold: NewClinicianHold) => async (dispatch) => {
    await wrapApiCall(api.post("/ehr/clinicianHolds/", hold), dispatch);
    dispatch(getActiveHolds());
  };

export const updateHold: AsyncActionCreator =
  (hold: ClinicianHold) => async (dispatch) => {
    await wrapApiCall(
      api.patch(`/ehr/clinicianHolds/${hold.id}/`, hold),
      dispatch,
    );
    dispatch(getActiveHolds());
  };

export const deleteHold: AsyncActionCreator =
  (hold: ClinicianHold) => async (dispatch) => {
    await wrapApiCall(api.delete(`/ehr/clinicianHolds/${hold.id}/`), dispatch);
    dispatch(getActiveHolds());
  };

export const getCapacities: AsyncActionCreator =
  (setLoading: boolean = true) =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(clinicianActions.fetchCapacities());
    }
    const response = await wrapApiCall(
      api.get("/ehr/manualCapacities/"),
      dispatch,
    );

    dispatch(getRecentCapacities());
    dispatch(getRecentResolvedMatches());
  };

export const getRecentCapacities: AsyncActionCreator =
  () => async (dispatch) => {
    // for capacity history, we only care about the most recent capacities pulled before
    // the current matching session
    dispatch(getRecentResolvedMatches());
    // in v2, we guarantee one row per matching session per clinician, so we just get the most recent expired capacity
    const recentV2Capacities = await wrapApiCall(
      api.get(
        `/ehr/manualCapacitiesV2/mostRecent/?active=false&getBeforeDate=${moment().format(
          "YYYY-MM-DD",
        )}`,
      ),
      dispatch,
    );

    dispatch(clinicianActions.loadRecentCapacitiesV2(recentV2Capacities.data));
  };

  /***
   * DEPRECATED
   */
export const getRecentResolvedMatches: AsyncActionCreator =
  (
    startDate: Moment = GET_LATEST_MATCHING_SESSION().subtract(1, "week"),
    endDate: Moment = moment(),
  ) =>
  async (dispatch) => {
    dispatch(clinicianActions.loadRecentMatches([]));
  };

export const addCapacity: AsyncActionCreator =
  (capacity: ManualCapacity) => async (dispatch) => {
    await wrapApiCall(api.post(`/ehr/manualCapacities/`, capacity), dispatch);
    dispatch(getCapacities());
    dispatch(getSlotAvailabilities([capacity.clinician]));
  };

export const updateCapacity: AsyncActionCreator =
  (capacity: ManualCapacity) => async (dispatch) => {
    await wrapApiCall(
      api.patch(`/ehr/manualCapacities/${capacity.id}/`, capacity),
      dispatch,
    );
    dispatch(getCapacities());
    dispatch(getSlotAvailabilities([capacity.clinician]));
  };

export const deleteCapacity: AsyncActionCreator =
  (capacity: ManualCapacity) => async (dispatch) => {
    await wrapApiCall(
      api.delete(`/ehr/manualCapacities/${capacity.id}/`),
      dispatch,
    );
    dispatch(getCapacities());
    dispatch(getSlotAvailabilities([capacity.clinician]));
  };

export const getSlotAvailabilities: AsyncActionCreator =
  (clinicianIds: number[], setLoading: boolean = true) =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(clinicianActions.fetchSlotAvailabilities());
    }
    const response = await wrapApiCall(
      api.get(
        `/ehr/clinicians/slot_availabilities/?clinicianIds=${clinicianIds.join(
          ",",
        )}`,
      ),
      dispatch,
    );
    dispatch(clinicianActions.loadSlotAvailabilities(response.data));
  };

export const getSchedulingLink: AsyncActionCreator =
  (clinicianId: number, procedureType: Procedure = "teletherapy") =>
  async (dispatch) => {
    try {
      const response = await wrapApiCall(
        api.get(
          `/api/expiringLinks/v1/schedulingLink/${clinicianId}?procedure_type=${procedureType}`,
        ),
        dispatch,
      );
      const link = response.data;
      window.open(link, "_blank");
    } catch (e) {
      console.error("Unable to get Scheduling Link", e);
      message.error(
        "Error finding Acuity Scheduling Link - please contact techsupport@twochairs.com",
      );
    }
  };

export const getManualSlots: AsyncActionCreator =
  (setLoading: boolean = true) =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(clinicianActions.fetchManualSlots());
    }
    const response = await wrapApiCall(api.get("/ehr/manualSlots/"), dispatch);
    dispatch(clinicianActions.loadManualSlots(response.data));
  };

export const getManualSlotsByClinicianId: AsyncActionCreator =
  (clinicianId: number, setLoading: boolean = true) =>
  async (dispatch) => {
    if (setLoading) {
      dispatch(clinicianActions.fetchManualSlots());
    }
    try {
      const response = await wrapApiCall(
        api.post("/ehr/manualSlots/get_by_clinician_id/", { clinicianId }),
        dispatch,
      );
      dispatch(
        clinicianActions.loadClinicianManualSlots({
          clinicianId,
          manualSlots: response.data,
        }),
      );
    } catch (e) {
      message.error(
        "There was an error getting Manual Slot data for a clinician",
      );
    }
  };

export const updateManualSlots: AsyncActionCreator =
  (clinicianId: number, slots: ClinicianSlot[]) => async (dispatch) => {
    await wrapApiCall(
      api.post(`/ehr/manualSlots/update_slots/`, { clinicianId, slots }),
      dispatch,
    );
    dispatch(getManualSlotsByClinicianId(clinicianId));
    dispatch(getSlotAvailabilities([clinicianId]));
  };

export const findSurveyLinks: AsyncActionCreator =
  (inputText: string) => async (dispatch) => {
    const requestBody = {};
    let response: AxiosResponse<CoupleExtended>;
    if (inputText.includes("@")) {
      try {
        const re = /\S+@\S+\.\S+/;
        if (re.test(inputText)) {
          requestBody["email"] = inputText;
          response = await wrapApiCall(
            api.post(
              "/api/couples/v1/resolveCoupleBySingleEmail/",
              requestBody,
            ),
            dispatch,
          );
        } else {
          throw new Error("Invalid email syntax");
        }
      } catch (e) {
        dispatch(
          clinicianActions.setConsentSurveyModalData("Email not found."),
        );
        return;
      }
    } else if (inputText.includes("coach.welkinhealth.com")) {
      try {
        const welkin_patient_id = inputText.match(
          /\/([a-f\d-]{36})\/timeline/,
        )?.[1];
        if (welkin_patient_id === undefined) {
          dispatch(
            clinicianActions.setConsentSurveyModalData("Invalid Welkin URL."),
          );
          return;
        }
        requestBody["welkin_patient_id"] = welkin_patient_id;
        response = await wrapApiCall(
          api.post("/api/couples/v1/resolveCouple/", requestBody),
          dispatch,
        );
      } catch (e) {
        dispatch(
          clinicianActions.setConsentSurveyModalData(
            "Welkin patient not found.",
          ),
        );
        return;
      }
    } else {
      dispatch(
        clinicianActions.setConsentSurveyModalData(
          "Invalid input. Please input a client email or couple Welkin URL.",
        ),
      );
      return;
    }
    if (response?.status === 200) {
      const coupleId = response.data.id;
      const clientIds = [response.data.client_a, response.data.client_b];
      const clientsRoute = `/api/clients/v1/?ids=${clientIds.join(",")}`;
      const clientRes: AxiosResponse<ClientExtended[]> = await wrapApiCall(
        api.get(clientsRoute),
        dispatch,
      );
      const clients = clientRes.data;
      const isKpReferral = response.data.is_kp_referral;
      const consentSurveyModalData: ConsentSurveyModalData = {
        isKpReferral,
        clients: [],
      };

      for (const client of clients) {
        const identityToken = await wrapApiCall(
          api.post("api/identity_tokens/v1/", {
            client: client.id,
            couple: coupleId,
            context: "fulfiller_clinicians",
          }),
          dispatch,
        );
        consentSurveyModalData.clients.push({
          client: client,
          token: identityToken.data.token,
        });
      }

      dispatch(
        clinicianActions.setConsentSurveyModalData(consentSurveyModalData),
      );
    } else {
      dispatch(
        clinicianActions.setConsentSurveyModalData(
          "Something went wrong. Please contact P&E team.",
        ),
      );
    }
  };
